import React from "react";

function useViewPortVisibility(ref: React.RefObject<HTMLElement | null>) {
  const [isIntersecting, setIntersecting] = React.useState(false);

  const observer = React.useMemo(() => {
    if (typeof window === "undefined") return;
    return new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );
  }, []);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (!observer) {
      return;
    }
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [observer, ref]);

  return isIntersecting;
}

export { useViewPortVisibility };
